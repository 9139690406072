import React, {useEffect, useState} from 'react';
import {Col, Popconfirm, Space} from 'antd';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

// Store
import {useAppDispatch} from '../../store/store';
import {useSelector} from 'react-redux';
import * as thresholdSelectors from '../../store/redux/selectors/thresholdSelectors';
import {getAllThresholds, removeThreshold} from '../../store/redux/actions/thresholdActions';

// Types
import {ThresholdType} from '../../types/thresholdTypes';
import {PaginationType} from '../../types/sharedTypes';

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';
import ThresholdFormModal from './ThresholdFormModal';
import FiltersComponent from '../subComponents/FiltersComponent';

// Functions and Constants
import {getFilterParameters, getTableColumns} from '../../utils/componentsUtils';


const ThresholdTable = () => {
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1,
    size: 10,
    text: ''
  });
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [thresholdToEdit, setThresholdToEdit] = useState<ThresholdType | null>();
  const dispatch = useAppDispatch();
  const { rows,count, size, page } = useSelector(thresholdSelectors.allThresholdsSelector);
  const filterParameters = getFilterParameters('threshold')

  const handleFormModal = (visible: boolean, thresholdId?: string) => {
    if(visible){
      const thresholdToEdit = rows.find(threshold => threshold.thresholdId === thresholdId);
      setThresholdToEdit(thresholdToEdit);
    } else {
      setThresholdToEdit(null);
    }
    setFormModalVisible(visible);
  }

  const actionButtons = (thresholdId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, thresholdId)} />
        <Popconfirm title={'¿Deseas borrar este umbral'} okText={'Confirmar'} cancelText={'Cancelar'}
                    onConfirm={() => dispatch(removeThreshold(thresholdId))}>
          <DeleteOutlined className={'cursor-pointer hover:text-red-dkt-400'} />
        </Popconfirm>
      </Space>
    )
  };

  useEffect(() => {
    dispatch(getAllThresholds(paginationData));
  }, [paginationData]);

  return (
    <>
      <Col md={24} xs={24} className={'mt-5'}>
        <FiltersComponent filterParameters={filterParameters} 
                          onChangeFilters={({ text }) => setPaginationData({ page, size, text: text })} />
      </Col>

      <TableComponent pageSize={size} currentPage={page} total={count} filterText={paginationData.text ?? ''}
                      dataSource={rows} scrollX={800} rowKey={'thresholdId'}
                      columns={getTableColumns('threshold', actionButtons)}
                      onChangePage={setPaginationData} />

      {formModalVisible &&
        <ThresholdFormModal thresholdToEdit={thresholdToEdit ?? null}
                            onToggleModal={handleFormModal} />
      }
    </>
  );
}

export default ThresholdTable;