import request from './requestWrapper';

// Types
import {PaginationType} from '../types/sharedTypes';
import {CtnType} from '../types/ctnTypes';

function getTemplate() {
  return request({
    url: 'ctn/getTemplate',
    method: 'GET',
  });
}
function getAll(data: PaginationType) {
  return request({
    url: 'ctn/getAllPaginated',
    method: 'POST',
    data: data
  });
}
function upload(formData: FormData) {
  return request({
    url: 'ctn/upload',
    method: 'POST',
    data: formData,
  });
}
function create(ctn: CtnType) {
  return request({
    url: 'ctn/create',
    method: 'POST',
    data: ctn,
  });
}
function update(ctn: CtnType) {
  return request({
    url: 'ctn/update',
    method: 'PUT',
    data: ctn,
  });
}
function remove(ctnId: string) {
  return request({
    url: `ctn/delete/${ctnId}`,
    method: 'DELETE',
  });
}
function deleteAllByContainer(containerNumber: string) {
  return request({
    url: `ctn/deleteAllByContainer/${containerNumber}`,
    method: 'DELETE',
  });
}

export default {
  getTemplate,
  getAll, upload, create,
  update, remove,
  deleteAllByContainer
};
