// Libraries
import React, {FunctionComponent} from 'react';
import {Row, Col, Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';

// Store
import {updateRemission} from '../../store/redux/actions/remissionActions';

// Types
import {RemissionType} from '../../types/remissionTypes';

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type RemissionFormModalType = {
  remissionToEdit: RemissionType | null;
  onToggleModal: (visible: boolean) => void;
};
const RemissionFormModal: FunctionComponent<RemissionFormModalType> = (
  {
    remissionToEdit, onToggleModal
  }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleSubmit = (values: RemissionType) => {
    values.remissionId = remissionToEdit!.remissionId;

    dispatch(updateRemission(values));
    onToggleModal(false);
  }

  return (
    <Modal title={'Editar remisión'} width={900}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={'Editar'}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout="vertical" form={form} initialValues={remissionToEdit ?? {}} onFinish={handleSubmit}>
        <Row gutter={10}>
          <Col md={8} xs={24}>
            <FormItemComponent name={'shippingNumber'} label={'N° de envío'} required={true}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'containerNumber'} label={'N° contenedor'} required={true}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'po'} label={'PO'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'bl'} label={'BL'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'ns'} label={'NS'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'manifest'} label={'Manifiesto'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'modelCode'} label={'Código del modelo'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'itemCode'} label={'Código del elemento'} required={true}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'originCountry'} label={'País de origen'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={24} xs={24}>
            <FormItemComponent name={'data'} label={'Datos'} required={true}
                               child={<InputComponent type={'textarea'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'quantity'} label={'Cantidad'} required={true}
                               child={<InputComponent type={'number'} disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'price'} label={'Precio USD'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'totalValue'} label={'Valor total USD'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'netWeight'} label={'Peso neto'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'grossWeight'} label={'Peso bruto'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'numberBl'} label={'#BL'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'remission'} label={'Remisión'} required={false}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'fiscalYear'} label={'Año fiscal'} required={false}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'origin'} label={'Origen'} required={false}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'freightValuePcs'} label={'Precio del flete pcs'} required={false}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'fob'} label={'Peso bruto'} required={false}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'thresholdValue'} label={'Valor del umbral'} required={false}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'thresholdStatus'} label={'Estado del umbral'} required={false}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent label={'Existe inbound'} required={false} 
                               child={<InputComponent value={remissionToEdit?.inbound ? 'Si' : 'No'} 
                                      disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent label={'Existe Ctn'} required={false} 
                               child={<InputComponent value={remissionToEdit?.ctn ? 'Si' : 'No'} 
                                      disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent label={'Existe tarifa'} required={false} 
                               child={<InputComponent value={remissionToEdit?.tariff ? 'Si' : 'No'} 
                                      disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent label={'Existe umbral'} required={false} 
                               child={<InputComponent value={remissionToEdit?.threshold ? 'Si' : 'No'} 
                                      disabled={true} />}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

RemissionFormModal.propTypes = {
  remissionToEdit: PropTypes.any.isRequired,
  onToggleModal: PropTypes.func.isRequired,
};
RemissionFormModal.defaultProps = {
  remissionToEdit: null,
  onToggleModal: () => {
    return null;
  }
};

export default RemissionFormModal;