// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import thresholdServices from '../../../api/thresholdServices';

// Types
import {thresholdTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {ThresholdType} from '../../../types/thresholdTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Constants
import baseURL from '../../../api/baseURL';


export const getAllThresholds = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  thresholdServices.getAll(data)
    .then((response) => {
      dispatch({
        type: thresholdTypes.GET_ALL_THRESHOLDS,
        thresholdResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getThresholdTemplate = () => async (dispatch: AppDispatch) => {  
  dispatch(setLoading(true));
  thresholdServices.getTemplate()
    .then((response) => {
      window.open(baseURL + response.data.excelUrl);
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const uploadThresholdFile = (formData: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  thresholdServices.upload(formData)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllThresholds({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const updateThreshold = (threshold: ThresholdType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  thresholdServices.update(threshold)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllThresholds({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const removeThreshold = (thresholdId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  thresholdServices.remove(thresholdId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllThresholds({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}