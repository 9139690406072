import request from './requestWrapper';

// Types
import {PaginationType} from '../types/sharedTypes';
import {UserType} from '../types/userTypes';

function getParams() {
  return request({
    url: 'user/getParams',
    method: 'GET',
  });
}
function getAll(data: PaginationType) {
  return request({
    url: 'user/getAll',
    method: 'GET',
    params: data
  });
}
function create(data: UserType) {
  return request({
    url: 'user/create',
    method: 'POST',
    data: data
  });
}
function update(data: UserType) {
  return request({
    url: 'user/update',
    method: 'PUT',
    data: data
  });
}
function remove(userId: string) {
  return request({
    url: `user/delete/${userId}`,
    method: 'DELETE',
  });
}

export default {
  getParams, getAll,
  create,
  update,
  remove
}