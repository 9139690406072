// Libraries
import React, {FunctionComponent} from 'react';
import {Row, Col, Form, Modal} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';

// Store
import {deleteAllByContainer} from '../../store/redux/actions/ctnActions';

// Types
import {CtnType} from '../../types/ctnTypes';

// Components and Subcomponents
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';
import {ERROR_MODAL} from '../subComponents/responseModals';


type DeleteAllByContainerFormModal = {
  containerNumber: string;
  onToggleModal: (visible: boolean) => void;
};
const DeleteAllByContainerFormModal: FunctionComponent<DeleteAllByContainerFormModal> = (
  {
    containerNumber, onToggleModal
  }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleSubmit = (values: CtnType) => {
    const isValidContainerNumber =
      values.containerNumber === containerNumber;

    if(isValidContainerNumber){
      dispatch(deleteAllByContainer(values.containerNumber));
      onToggleModal(false);
    }else{
      ERROR_MODAL('El número de contenedor no coincide con el buscado.');
    }
  }

  return (
    <Modal title={'Eliminar todos los registros del contenedor'} width={400}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={'Confirmar'}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={10}>
          <Col xs={24} className={'mb-3'}>
            <span>
              La siguiente acción eliminará todos los registros asociados al número de contenedor ingresado.
            </span>
            <br/>
            <br/>
            <span>
              Por favor ingrese nuevamente el número del contenedor para confirmar.
            </span>
          </Col>
          <Col xs={24}>
            <FormItemComponent name={'containerNumber'} label={'Número contenedor'} required={true}
                               child={<InputComponent/>}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

DeleteAllByContainerFormModal.propTypes = {
  containerNumber: PropTypes.string.isRequired,
  onToggleModal: PropTypes.func.isRequired,
};
DeleteAllByContainerFormModal.defaultProps = {
  containerNumber: '',
  onToggleModal: () => {
    return null;
  }
};

export default DeleteAllByContainerFormModal;