// Libraries
import React, {FunctionComponent, useEffect, useState} from 'react';
import {List, Row, Col, Tooltip, Popconfirm, Space} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';
import {EditOutlined, DeleteOutlined, ToolOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';

// Store
import * as userSelectors from '../../store/redux/selectors/userSelectors';

// Functions
import {screenSizeValidator} from '../../utils/functions';

// Types
import {UserType} from '../../types/userTypes';
import {getUserParams, removeUser} from '../../store/redux/actions/userActions';


type UserListType = {
  onToggleModal: (visible: boolean, isEditing?: boolean, userToEdit?: UserType) => void;
}
const UserList: FunctionComponent<UserListType> = (
  {
    onToggleModal,
  }) => {
    const [paginationData, setPaginationData] = useState({
      page: 1,
      size: 10,
    });
    const dispatch = useAppDispatch();
    const params = useSelector(userSelectors.userParamsSelector);
    const users = useSelector(userSelectors.allUsersSelector);
    const isMD = screenSizeValidator('(max-width: 920px)');
    const roleTypes = params.roleTypes;

    useEffect(() => {
      dispatch(getUserParams(paginationData));
    }, []);

    return (
       <List dataSource={users} grid={{ gutter: 16, column: isMD ? 1 : 2 }}
             renderItem={(user) => (
               <List.Item>
                 <Row className={'flex'}>
                   <Col md={20} sm={20} xs={24}>
                     <p className={'font-medium'}>{user.email}</p>
                     <p>
                       <ToolOutlined title={'Rol'} className={'mr-2'} />
                       {roleTypes.find((param) => param.id === user.role)?.value}
                     </p>
                   </Col>
                   <Col md={4} sm={4} xs={0} className={'hidden sm:block md:block my-auto sm:text-right'}>
                     <Tooltip title={'Editar'}>
                       <EditOutlined className={'inline-block mx-2 text-blue-dkt-400'}
                                     onClick={() => onToggleModal(true, true, user)} />
                     </Tooltip>
                     <Tooltip title={'Eliminar'}>
                       <Popconfirm title={'¿Quieres eliminar este usuario?'} okText={'Confirmar'} cancelText={'Cancelar'}
                                   onConfirm={() => dispatch(removeUser(user.userId))}>
                         <DeleteOutlined className={'inline-block mx-2 text-red-dkt-400'} />
                       </Popconfirm>
                     </Tooltip>
                   </Col>
                   <Col md={0} sm={0} xs={24} className={'block sm:hidden md:hidden mx-auto sm:my-auto md:my-auto sm:text-right text-center'}>
                     <Space size={'middle'}>
                       <p className={'text-blue-dkt-400 cursor-pointer'} onClick={() => onToggleModal(true, true, user)}>
                         <EditOutlined /> Editar
                       </p>
                       <Popconfirm title={'¿Quieres eliminar este usuario?'} okText={'Confirmar'} cancelText={'Cancelar'}
                                   onConfirm={() => dispatch(removeUser(user.userId))}>
                         <p className={'text-red-dkt-400 cursor-pointer'}>
                           <DeleteOutlined /> Eliminar
                         </p>
                       </Popconfirm>
                     </Space>
                   </Col>
                 </Row>
               </List.Item>
             )} />
  );
}

UserList.propTypes = {
  onToggleModal: PropTypes.func.isRequired,
};
UserList.defaultProps = {
  onToggleModal: () => {
    return null;
  },
};

export default UserList;