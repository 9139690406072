// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import tariffServices from '../../../api/tariffServices';

// Types
import {tariffTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {TariffType} from '../../../types/tariffTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Constants
import baseURL from '../../../api/baseURL';


export const getAllTariffs = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  tariffServices.getAll(data)
    .then((response) => {
      dispatch({
        type: tariffTypes.GET_ALL_TARIFFS,
        tariffResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getTariffTemplate = () => async (dispatch: AppDispatch) => {  
  dispatch(setLoading(true));
  tariffServices.getTemplate()
    .then((response) => {
      window.open(baseURL + response.data.excelUrl);
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const uploadTariffFile = (formData: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  tariffServices.upload(formData)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllTariffs({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const updateTariff = (tariff: TariffType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  tariffServices.update(tariff)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllTariffs({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const removeTariff = (tariffId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  tariffServices.remove(tariffId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllTariffs({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}