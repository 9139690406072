// Libraries
import React, {FunctionComponent} from 'react';
import {Row, Col, Form, Modal, DatePicker} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';
import moment from 'moment';

// Store
import {createCTN, updateCTN} from '../../store/redux/actions/ctnActions';

// Types
import {CtnType} from '../../types/ctnTypes';

// Components and Subcomponents
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';

// Functions and Constants
import {MODAL_ACTIONS} from '../../utils/constants';
import {textCleanup} from '../../utils/functions';

type CtnFormModalType = {
  modalAction: string | null;
  ctnToEdit: CtnType | null;
  onToggleModal: (visible: boolean) => void;
};
const CtnFormModal: FunctionComponent<CtnFormModalType> = (
  {
    modalAction, ctnToEdit, onToggleModal
  }) => {
  const [form] = Form.useForm();
  const itemCode = Form.useWatch('itemCode', form) ?? '';
  const blnb = Form.useWatch('blnb', form) ?? '';
  const loadingPort = Form.useWatch('loadingPort', form) ?? '';
  const containerSize = Form.useWatch('containerSize', form) ?? '';
  const dispatch = useAppDispatch();
  const editCTN = modalAction === MODAL_ACTIONS.edit;

  const handleSubmit = (values: CtnType) => {
    values.etd = moment(values.etd).format('YYYY-MM-DD');
    values.atd = moment(values.atd).format('YYYY-MM-DD');
    values.eta = moment(values.eta).format('YYYY-MM-DD');
    values.remission = 
      values.itemCode.toUpperCase() + values.blnb.toUpperCase();
    values.originContainerType = 
      values.loadingPort.toUpperCase() + values.containerSize.toUpperCase();
    
    if(editCTN){
      values.ctnId = ctnToEdit!.ctnId;
      dispatch(updateCTN(values));
    }else{
      values.containerNumber = textCleanup(values.containerNumber);
      values.orderNb = textCleanup(values.orderNb);
      values.loadingPort = textCleanup(values.loadingPort);
      values.containerSize = textCleanup(values.containerSize);
      values.itemCode = textCleanup(values.itemCode);
      values.blnb = textCleanup(values.blnb);
      dispatch(createCTN(values));
    }
    
    onToggleModal(false);
  }

  return (
    <Modal title={editCTN ? 'Editar CTN' : 'Nuevo CTN'} width={900}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={editCTN ? 'Editar' : 'Crear'}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout="vertical" form={form} initialValues={ctnToEdit ?? {}} onFinish={handleSubmit}>
        <Row gutter={10}>
          <Col md={8} xs={24}>
            <FormItemComponent name={'containerNumber'} label={'Número contenedor'} required={true}
                               child={<InputComponent disabled={editCTN} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'etd'} label={'ETD'} required={true}
                               child={<DatePicker allowClear={false} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'atd'} label={'ATD'} required={true}
                               child={<DatePicker allowClear={false} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'eta'} label={'ETA'} required={true}
                               child={<DatePicker allowClear={false} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'vesselName'} label={'Embarcación'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'voyage'} label={'Pasaje'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'loadingPort'} label={'Puerto carga'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'dischargePort'} label={'Puerto descarga'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'containerSize'} label={'Tamaño contenedor'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'orderNb'} label={'Número pedido'} required={true}
                               child={<InputComponent type={'number'} disabled={editCTN} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'modelCode'} label={'Código modelo'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'itemCode'} label={'Código artículo'} required={true}
                               child={<InputComponent type={'number'} disabled={editCTN} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'itemSize'} label={'Tamaño artículo'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'ctnQuantity'} label={'Cantidad CTN'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'itemQuantity'} label={'Cantidad artículo'} required={true}
                               child={<InputComponent type={'number'} disabled={editCTN} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'gw'} label={'GW'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'nw'} label={'NW'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'volume'} label={'Volumen'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'pcb'} label={'PCB'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'dpp'} label={'DPP'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'forwarder'} label={'Promotor'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'blnb'} label={'Blnb'} required={true}
                               child={<InputComponent disabled={editCTN} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent label={'Remisión'} required={true}
                               child={<InputComponent value={itemCode.toUpperCase() + blnb.toUpperCase()} 
                                    disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent label={'Tipo contenedor origen'} required={true}
                               child={<InputComponent value={loadingPort.toUpperCase() + containerSize.toUpperCase()} 
                                    disabled={true}/>}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'fiscalYear'} label={'Año fiscal'} required={false}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'freightValueTender'} label={'Valor flete'} required={false}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'freightValueWeight'} label={'Peso flete'} required={false}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={8} xs={24}>
            <FormItemComponent name={'freightValuePcs'} label={'PCS flete'} required={false}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

CtnFormModal.propTypes = {
  modalAction: PropTypes.string,
  ctnToEdit: PropTypes.any,
  onToggleModal: PropTypes.func.isRequired,
};
CtnFormModal.defaultProps = {
  modalAction: '',
  ctnToEdit: null,
  onToggleModal: () => {
    return null;
  }
};

export default CtnFormModal;