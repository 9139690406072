// Constants
import {thresholdTypes} from '../types';
import {ThresholdActionType} from '../../../types/thresholdTypes';

const initialState = {
  thresholdResult: {
    count: 0,
    page: 1,
    size: 10,
    rows: []
  },
}

const thresholdReducer = (state = initialState, action: ThresholdActionType) => {
  switch (action.type) {
    case thresholdTypes.GET_ALL_THRESHOLDS:
      return {
        ...state,
        thresholdResult: action.thresholdResult
      };
    default:
      return state;
  }
};

export default thresholdReducer;
