// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import userServices from '../../../api/userServices';

// Types
import {userTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';
import {UserType} from '../../../types/userTypes';


export const getUserParams = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.getParams()
    .then((response) => {
      dispatch({
        type: userTypes.GET_USER_PARAMS,
        params: response.data
      });
      dispatch(getAllUsers(data));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllUsers = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.getAll(data)
    .then((response) => {
      dispatch({
        type: userTypes.GET_ALL_USERS,
        users: response.data.rows
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const createUser = (data: UserType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.create(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllUsers({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const updateUser = (data: UserType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.update(data)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllUsers({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const removeUser = (userId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  userServices.remove(userId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllUsers({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}