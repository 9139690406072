// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import inboundServices from '../../../api/inboundServices';

// Types
import {inboundTypes} from '../types';
import {PaginationType} from '../../../types/sharedTypes';
import {InboundType} from '../../../types/inboundTypes';

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';

// Constants
import baseURL from '../../../api/baseURL';


export const getAllInbounds = (data: PaginationType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  inboundServices.getAll(data)
    .then((response) => {
      dispatch({
        type: inboundTypes.GET_ALL_INBOUNDS,
        inboundResult: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getInboundTemplate = () => async (dispatch: AppDispatch) => {  
  dispatch(setLoading(true));
  inboundServices.getTemplate()
    .then((response) => {
      window.open(baseURL + response.data.excelUrl);
      dispatch(setLoading(false));
    })
    .catch(response => {
      ERROR_MODAL(response.data.error);
      dispatch(setLoading(false));
    })
}
export const uploadInboundFile = (formData: FormData) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  inboundServices.upload(formData)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllInbounds({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const updateInbound = (inbound: InboundType) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  inboundServices.update(inbound)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllInbounds({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const removeInbound = (inboundId: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  inboundServices.remove(inboundId)
    .then((response) => {
      SUCCESS_MODAL(response.data.status);
      dispatch(getAllInbounds({ page: 1, size: 10 }));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}