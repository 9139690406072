// Constants
import {ctnTypes} from '../types';
import {CtnActionType} from '../../../types/ctnTypes';

const initialState = {
  ctnResult: {
    count: 0,
    page: 1,
    size: 10,
    rows: []
  },
}

const ctnReducer = (state = initialState, action: CtnActionType) => {
  switch (action.type) {
    case ctnTypes.GET_ALL_CTN:
      return {
        ...state,
        ctnResult: action.ctnResult
      };
    default:
      return state;
  }
};

export default ctnReducer;
