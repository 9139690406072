// Libraries
import React, {FunctionComponent} from 'react';
import {Row, Col, Form, Modal} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';

// Store
import {updateTariff} from '../../store/redux/actions/tariffActions';

// Types
import {TariffType} from '../../types/tariffTypes';

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type TariffFormModalType = {
  tariffToEdit: TariffType | null;
  onToggleModal: (visible: boolean) => void;
};
const TariffFormModal: FunctionComponent<TariffFormModalType> = (
  {
    tariffToEdit, onToggleModal
  }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleSubmit = (values: TariffType) => {
    values.tariffId = tariffToEdit!.tariffId;
    dispatch(updateTariff(values));
    onToggleModal(false);
  }

  return (
    <Modal title={'Editar tarifa'}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={'Editar'}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout="vertical" form={form} initialValues={tariffToEdit ?? {}} onFinish={handleSubmit}>
        <Row gutter={10}>
          <Col md={12} xs={24}>
            <FormItemComponent name={'containerNumber'} label={'Número contenedor'} required={true}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'code'} label={'Código'} required={true}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'year'} label={'Año'} required={true}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'origin'} label={'Origen'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'type'} label={'Tipo'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'shippingCompany'} label={'Compañía naviera'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'freight'} label={'Flete'} required={true}
                               child={<InputComponent type={'number'} disabled={true} />}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

TariffFormModal.propTypes = {
  tariffToEdit: PropTypes.any.isRequired,
  onToggleModal: PropTypes.func.isRequired,
};
TariffFormModal.defaultProps = {
  tariffToEdit: null,
  onToggleModal: () => {
    return null;
  }
};

export default TariffFormModal;