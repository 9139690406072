// Libraries
import React, {FunctionComponent} from 'react';
import {Row, Col, Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';

// Store
import {downloadFile} from '../../store/redux/actions/remissionActions';

// Types
import {RemissionExcelHeaderType} from '../../types/remissionTypes';

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type DownloadRemissionFormModalType = {
  shippingNumber: string | null;
  onToggleModal: (visible: boolean) => void;
};
const DownloadRemissionFormModal: FunctionComponent<DownloadRemissionFormModalType> = (
  {
    shippingNumber,
    onToggleModal
  }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleSubmit = (values: RemissionExcelHeaderType) => {
    dispatch(downloadFile(values));
    onToggleModal(false);
  }

  return (
    <Modal title={'Generar reporte'}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={'Descargar'}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout="vertical" form={form} initialValues={{shippingNumber:shippingNumber}} 
            onFinish={handleSubmit}>
        <Row gutter={10}>
          <Col md={12} xs={24}>
            <FormItemComponent name={'shippingNumber'} label={'N° de envío'} required={true}
                               child={<InputComponent disabled={false} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'originCountry'} label={'País de origen'} required={false}
                               child={<InputComponent disabled={false} />}
            />
          </Col>
          <Col md={24} xs={24}>
            <FormItemComponent name={'shipper'} label={'Shipper'} required={false}
                               child={<InputComponent type={'textarea'} disabled={false} />}
            />
          </Col>
          <Col md={24} xs={24}>
            <FormItemComponent name={'departure'} label={'Salida'} required={false}
                               child={<InputComponent type={'textarea'} disabled={false} />}
            />
          </Col>
          <Col md={24} xs={24}>
            <FormItemComponent name={'destiny'} label={'Destino'} required={false}
                               child={<InputComponent type={'textarea'} disabled={false} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'provenanceCountry'} label={'País de procedencia'} required={false}
                               child={<InputComponent disabled={false} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'packagingType'} label={'Tipo de empaque'} required={false}
                               child={<InputComponent disabled={false} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'incoterm'} label={'Incoterm'} required={false}
                               child={<InputComponent disabled={false} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'remission'} label={'Remisión'} required={false}
                               child={<InputComponent disabled={false} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'totalVolume'} label={'Volumen total'} required={false}
                               child={<InputComponent disabled={false} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'totalPallets'} label={'Paletas totales'} required={false}
                               child={<InputComponent disabled={false} />}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

DownloadRemissionFormModal.propTypes = {
  shippingNumber: PropTypes.string,
  onToggleModal: PropTypes.func.isRequired,
};
DownloadRemissionFormModal.defaultProps = {
  shippingNumber: '',
  onToggleModal: () => {
    return null;
  }
};

export default DownloadRemissionFormModal;