import request from './requestWrapper';

// Types
import {PaginationType} from '../types/sharedTypes';
import {RemissionExcelHeaderType, RemissionType} from '../types/remissionTypes';

function getTemplate() {
  return request({
    url: 'remission/getTemplate',
    method: 'GET',
  });
}
function getAll(data: PaginationType) {
  return request({
    url: 'remission/getAllPaginated',
    method: 'POST',
    data: data
  });
}
function upload(formData: FormData) {
  return request({
    url: 'remission/upload',
    method: 'POST',
    data: formData
  });
}
function downloadFile(remissionHeader: RemissionExcelHeaderType) {
  return request({
    url: 'remission/download',
    method: 'POST',
    data: remissionHeader
  });
}
function update(remission: RemissionType) {
  return request({
    url: 'remission/update',
    method: 'PUT',
    data: remission,
  });
}
function remove(remissionId: string) {
  return request({
    url: `remission/delete/${remissionId}`,
    method: 'DELETE',
  });
}

export default {
  getAll, getTemplate, upload, update, downloadFile, remove
}