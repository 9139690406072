// Constants
import {tariffTypes} from '../types';
import {TariffActionType} from '../../../types/tariffTypes';

const initialState = {
  tariffResult: {
    count: 0,
    page: 1,
    size: 10,
    rows: []
  },
}

const tariffReducer = (state = initialState, action: TariffActionType) => {
  switch (action.type) {
    case tariffTypes.GET_ALL_TARIFFS:
      return {
        ...state,
        tariffResult: action.tariffResult
      };
    default:
      return state;
  }
};

export default tariffReducer;
