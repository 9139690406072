import React, {useEffect, useState} from 'react';
import {Col, Popconfirm, Space} from 'antd';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import moment from 'moment';

// Store
import {useAppDispatch} from '../../store/store';
import {useSelector} from 'react-redux';
import * as inboundSelectors from '../../store/redux/selectors/inboundSelectors';
import {getAllInbounds, removeInbound} from '../../store/redux/actions/inboundActions';

// Types
import {InboundType} from '../../types/inboundTypes';
import {PaginationType} from '../../types/sharedTypes';

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';
import InboundFormModal from './InboundFormModal';
import FiltersComponent from '../subComponents/FiltersComponent';

// Functions and Constants
import {getFilterParameters, getTableColumns} from '../../utils/componentsUtils';


const InboundTable = () => {
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1,
    size: 10,
    text: ''
  });
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [inboundToEdit, setInboundToEdit] = useState<InboundType | null>();
  const dispatch = useAppDispatch();
  const { rows, count, size, page } = useSelector(inboundSelectors.allInboundsSelector);
  const filterParameters = getFilterParameters('inbound')

  useEffect(() => {
    dispatch(getAllInbounds(paginationData));
  }, [paginationData]);

  const handleFormModal = (visible: boolean, inboundId?: string) => {
    setFormModalVisible(visible);
    if(visible){
      const inboundToEdit = JSON.parse(JSON.stringify(rows.find(inbound => inbound.inboundId === inboundId)));
      inboundToEdit!.etaDate = moment(inboundToEdit!.etaDate);
      inboundToEdit!.ataDate = moment(inboundToEdit!.ataDate);
      setInboundToEdit(inboundToEdit);
    } else {
      setInboundToEdit(null);
    }
  }

  const actionButtons = (inboundId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, inboundId)} />
        <Popconfirm title={'¿Deseas borrar este inbound?'} okText={'Confirmar'} cancelText={'Cancelar'}
                    onConfirm={() => dispatch(removeInbound(inboundId))}>
          <DeleteOutlined className={'cursor-pointer hover:text-red-dkt-400'} />
        </Popconfirm>
      </Space>
    )
  };

  return (
    <>
      <Col md={24} xs={24} className={'mt-5'}>
        <FiltersComponent filterParameters={filterParameters} 
                          onChangeFilters={({ text }) => setPaginationData({ page, size, text: text })} />
      </Col>

      <TableComponent pageSize={size} currentPage={page} total={count} filterText={paginationData.text ?? ''}
                      dataSource={rows} scrollX={1500} rowKey={'inboundId'}
                      columns={getTableColumns('inbound', actionButtons)}
                      onChangePage={setPaginationData} />

      {formModalVisible &&
        <InboundFormModal inboundToEdit={inboundToEdit ?? null}
                          onToggleModal={handleFormModal} />
      }
    </>
  );
}

export default InboundTable;