// Libraries
import React, {FunctionComponent} from 'react';
import {Row, Col, Form, Modal, DatePicker} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';
import moment from 'moment';

// Store
import {updateInbound} from '../../store/redux/actions/inboundActions';

// Types
import {InboundType} from '../../types/inboundTypes';

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';
import SelectComponent from '../subComponents/SelectComponent';

// Functions and Components
import {getMonths} from '../../utils/functions';


type InboundFormModalType = {
  inboundToEdit: InboundType | null;
  onToggleModal: (visible: boolean) => void;
};
const InboundFormModal: FunctionComponent<InboundFormModalType> = (
  {
    inboundToEdit, onToggleModal
  }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleSubmit = (values: InboundType) => {
    values.inboundId = inboundToEdit!.inboundId;
    values.etaDate = moment(values.etaDate).format('YYYY-MM-DD');
    values.ataDate = moment(values.ataDate).format('YYYY-MM-DD');
    dispatch(updateInbound(values));
    onToggleModal(false);
  }

  return (
    <Modal title={'Editar inbound'}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={'Editar'}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout="vertical" form={form} initialValues={inboundToEdit ?? {}} onFinish={handleSubmit}>
        <Row gutter={10}>
          <Col md={12} xs={24}>
            <FormItemComponent name={'containerNumber'} label={'N° contenedor'} required={true}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'containerSize'} label={'Tamaño contenedor'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'etaWeek'} label={'Semana ETA'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'month'} label={'Mes'} required={true}
                               child={<SelectComponent list={getMonths()} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'etaDate'} label={'Fecha ETA'} required={true}
                               child={<DatePicker allowClear={false} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'ataDate'} label={'Fecha ATA'} required={true}
                               child={<DatePicker allowClear={false} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'bl'} label={'BL'} required={true}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'shippingLine'} label={'Línea de compra'} required={true}
                               child={<InputComponent />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'type'} label={'Tipo'} required={true}
                               child={<InputComponent />}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

InboundFormModal.propTypes = {
  inboundToEdit: PropTypes.any.isRequired,
  onToggleModal: PropTypes.func.isRequired,
};
InboundFormModal.defaultProps = {
  inboundToEdit: null,
  onToggleModal: () => {
    return null;
  }
};

export default InboundFormModal;