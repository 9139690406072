// Constants
import {inboundTypes} from '../types';
import {InboundActionType} from '../../../types/inboundTypes';

const initialState = {
  inboundResult: {
    count: 0,
    page: 1,
    size: 10,
    rows: []
  },
}

const inboundReducer = (state = initialState, action: InboundActionType) => {
  switch (action.type) {
    case inboundTypes.GET_ALL_INBOUNDS:
      return {
        ...state,
        inboundResult: action.inboundResult
      };
    default:
      return state;
  }
};

export default inboundReducer;
