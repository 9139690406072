import {message} from 'antd';

// Types
import {RemissionType} from '../types/remissionTypes';
import {ThresholdType} from '../types/thresholdTypes';
import {InboundType} from '../types/inboundTypes';
import {TariffType} from '../types/tariffTypes';
import {CtnType} from '../types/ctnTypes';
import {FilterParametersType} from '../types/sharedTypes';

// Functions
import {getLocalStorageItem, getMonths} from './functions';
import {ROLES} from './constants';

export const uploadProps = (acceptFormat: string, callback: (file: File) => void) => {
  return {
    multiple: false,
    showUploadList: false,
    accept: acceptFormat,
    customRequest({ file, onSuccess, onError }: any) {
      let error = false;

      if(file.type === '' || !acceptFormat.includes(file.type)){
        message.error({ content: `El archivo "${file.name}" no está permitido.` });
        error = true;
      } else if(file.size/1024/1024 > 14) {
        message.error({ content: `El archivo "${file.name}" sobrepasa el tamaño permitido (14MB).` });
        error = true;
      }

      if (error) {
        setTimeout(() => { onError('error'); }, 0);
        return;
      }

      setTimeout(() => { onSuccess('ok'); }, 0);
      callback(file);
    },
  };
}

export const getTableColumns = (module: string, actionButtons: (id: string) => void) => {
  const roleName = getLocalStorageItem('roleName');
  const isAdmin = roleName === ROLES.admin;
  let columns: any = [];

  if (module === 'ctn') {
    columns = [
      { title: 'Número contenedor', dataIndex: 'containerNumber', key: 'containerNumber', width: '150px' },
      { title: 'Embarcación', dataIndex: 'vesselName', key: 'vesselName', width: '200px', ellipsis: true  },
      { title: 'Pasaje', dataIndex: 'voyage', key: 'voyage', width: '100px' },
      { title: 'Puerto carga', dataIndex: 'loadingPort', key: 'loadingPort', width: '150px' },
      { title: 'Puerto descarga', dataIndex: 'dischargePort', key: 'dischargePort', width: '150px' },
      { title: 'Tamaño contenedor', dataIndex: 'containerSize', key: 'containerSize', width: '150px' },
      { title: 'Número pedido', dataIndex: 'orderNb', key: 'orderNb', width: '150px' },
      { title: 'Código modelo', dataIndex: 'modelCode', key: 'modelCode', width: '150px' },
      { title: 'Código artículo', dataIndex: 'itemCode', key: 'itemCode', width: '150px' },
      { title: 'Tamaño artículo', dataIndex: 'itemSize', key: 'itemSize', width: '150px' },
      { title: 'Cantidad CTN', dataIndex: 'ctnQuantity', key: 'ctnQuantity', width: '100px' },
      { title: 'Cantidad artículo', dataIndex: 'itemQuantity', key: 'itemQuantity', width: '150px' },
      { title: 'GW', dataIndex: 'gw', key: 'gw', width: '100px' },
      { title: 'NW', dataIndex: 'nw', key: 'nw', width: '100px' },
      { title: 'Volumen', dataIndex: 'volume', key: 'volume', width: '100px' },
      { title: 'PCB', dataIndex: 'pcb', key: 'pcb', width: '100px' },
      { title: 'DPP', dataIndex: 'dpp', key: 'dpp', width: '100px' },
      { title: 'Promotor', dataIndex: 'forwarder', key: 'forwarder', width: '150px', ellipsis: true },
      { title: 'Blnb', dataIndex: 'blnb', key: 'blnb', width: '150px' },
      { title: 'Remisión', dataIndex: 'remission', key: 'remission', width: '200px' },
      { title: 'Tipo contenedor origen', dataIndex: 'originContainerType', key: 'originContainerType', width: '200px', ellipsis: true },
      { title: 'Año fiscal', dataIndex: 'fiscalYear', key: 'fiscalYear', width: '150px' },
      { title: 'Valor flete', dataIndex: 'freightValueTender', key: 'freightValueTender', width: '150px' },
      { title: 'Peso flete', dataIndex: 'freightValueWeight', key: 'freightValueWeight', width: '150px',
        render: (value: number) => Number(value).toFixed(2) },
      { title: 'PCS flete', dataIndex: 'freightValuePcs', key: 'freightValuePcs', width: '100px',
        render: (value: number) => Number(value).toFixed(4) },
    ];
    isAdmin && columns.push({
      title: 'Acciones', dataIndex: 'actions', key: 'actions', width: '80px', fixed: 'right',
      render: (text: any, record: CtnType) => actionButtons(record.ctnId)
    });
  } else if (module === 'tariff') {
    columns = [
      { title: 'Año', dataIndex: 'year', key: 'year', width: '100px' },
      { title: 'Código', dataIndex: 'code', key: 'code', width: '150px' },
      { title: 'Origen', dataIndex: 'origin', key: 'origin', width: '150px' },
      { title: 'Tipo', dataIndex: 'type', key: 'type', width: '100px' },
      { title: 'Compañía naviera', dataIndex: 'shippingCompany', key: 'shippingCompany', width: '150px' },
      { title: 'Número contenedor', dataIndex: 'containerNumber', key: 'containerNumber', width: '150px',
        render: (value: any) => value ?? '-' },
      { title: 'Flete', dataIndex: 'freight', key: 'freight', width: '100px' }
    ];
    isAdmin && columns.push({
      title: 'Acciones', dataIndex: 'actions', key: 'actions', width: '80px', fixed: 'right',
      render: (text: any, record: TariffType) => actionButtons(record.tariffId)
    });
  } else if (module === 'threshold') {
    columns = [
      { title: 'Referencia', dataIndex: 'reference', key: 'reference', width: '100px' },
      { title: 'Subpartida', dataIndex: 'subDeparture', key: 'subDeparture', width: '100px' },
      { title: 'Tipo', dataIndex: 'type', key: 'type', width: '100px' },
      { title: 'Umbral', dataIndex: 'threshold', key: 'threshold', width: '100px' }
    ];
    isAdmin && columns.push({
      title: 'Acciones', dataIndex: 'actions', key: 'actions', width: '50px',
      render: (text: any, record: ThresholdType) => actionButtons(record.thresholdId)
    });
  } else if (module === 'remission') {
    columns = [
      { title: 'N° de envío', dataIndex: 'shippingNumber', key: 'shippingNumber', width: '100px' },
      { title: 'N° contenedor', dataIndex: 'containerNumber', key: 'containerNumber', width: '150px' },
      { title: 'PO', dataIndex: 'po', key: 'po', width: '100px' },
      { title: 'BL', dataIndex: 'bl', key: 'bl', width: '150px' },
      { title: 'NS', dataIndex: 'ns', key: 'ns', width: '100px' }, 
      { title: 'Manifiesto', dataIndex: 'manifest', key: 'manifest', width: '150px' },
      { title: 'Código del modelo', dataIndex: 'modelCode', key: 'modelCode', width: '150px' },
      { title: 'Código del artículo', dataIndex: 'itemCode', key: 'itemCode', width: '150px' },
      { title: 'País de origen', dataIndex: 'originCountry', key: 'originCountry', width: '150px' },
      { title: 'Cantidad', dataIndex: 'quantity', key: 'quantity', width: '100px' },
      { title: 'Datos', dataIndex: 'data', key: 'data', width: '350px', ellipsis: true },
      { title: 'Precio', dataIndex: 'price', key: 'price', width: '150px',
        render: (value: number) => Number(value).toFixed(2) },
      { title: 'Valor total', dataIndex: 'totalValue', key: 'totalValue', width: '150px',
        render: (value: number) => Number(value).toFixed(2) },
      { title: 'Peso neto', dataIndex: 'netWeight', key: 'netWeight', width: '150px',
        render: (value: number) => Number(value).toFixed(2) },
      { title: 'Peso bruto', dataIndex: 'grossWeight', key: 'grossWeight', width: '150px',
        render: (value: number) => Number(value).toFixed(2) },
      { title: '#BL', dataIndex: 'numberBl', key: 'numberBl', width: '50px' },
      { title: 'Remisión', dataIndex: 'remission', key: 'remission', width: '200px' },
      { title: 'Puerto de origen', dataIndex: 'origin', key: 'origin', width: '150px' },
      { title: 'Año fiscal', dataIndex: 'fiscalYear', key: 'fiscalYear', width: '100px' },
      { title: 'Precio del flete pcs', dataIndex: 'freightValuePcs', key: 'freightValuePcs', width: '200px',
        render: (value: number) => Number(value).toFixed(4) },
      { title: 'FOB', dataIndex: 'fob', key: 'fob', width: '100px', 
        render: (value: number) => Number(value).toFixed(4) },
      { title: 'Valor del umbral', dataIndex: 'thresholdValue', key: 'thresholdValue', width: '150px' },
      { title: 'Estado del umbral', dataIndex: 'thresholdStatus', key: 'thresholdStatus', width: '150px' }
    ];
    isAdmin && columns.push({
      title: 'Acciones', dataIndex: 'actions', key: 'actions', width: '80px', fixed: 'right',
      render: (text: any, record: RemissionType) => actionButtons(record.remissionId)
    });
  } else if (module === 'inbound') {
    columns = [
      { title: 'N° contenedor', dataIndex: 'containerNumber', key: 'containerNumber', width: '100px' },
      { title: 'Tamaño contenedor', dataIndex: 'containerSize', key: 'containerSize', width: '100px' },
      { title: 'Semana ETA', dataIndex: 'etaWeek', key: 'etaWeek', width: '100px' },
      { title: 'Mes', dataIndex: 'month', key: 'month', width: '100px',
        render: (value: number) => getMonths().find(month => month.id == value)!.value },
      { title: 'Fecha ETA', dataIndex: 'etaDate', key: 'etaDate', width: '100px' },
      { title: 'Fecha ATA', dataIndex: 'ataDate', key: 'ataDate', width: '100px' },
      { title: 'BL', dataIndex: 'bl', key: 'bl', width: '150px' },
      { title: 'Línea de compra', dataIndex: 'shippingLine', key: 'shippingLine', width: '100px' },
      { title: 'Tipo', dataIndex: 'type', key: 'type', width: '100px' }
    ];
    isAdmin && columns.push({
      title: 'Acciones', dataIndex: 'actions', key: 'actions', width: '50px', fixed: 'right',
      render: (text: any, record: InboundType) => actionButtons(record.inboundId)
    });
  } else {
    columns = [];
  }

  return columns;
}

export const getFilterParameters = (module: string) => {
  let parameters: FilterParametersType[] = [];

  if (module === 'ctn') {
    parameters = [
      {fieldName: 'containerNumber', placeHolder: 'Número de contenedor'},
      {fieldName: 'itemCode', placeHolder: 'Código del artículo'}
    ];
  } else if (module === 'tariff') {
    parameters = [
      {fieldName: 'text', placeHolder: 'Código o número de contenedor'},
    ];
  } else if (module === 'threshold') {
    parameters = [
      {fieldName: 'text', placeHolder: 'Referencia'},
    ];
  } else if (module === 'remission') {
    parameters = [
      {fieldName: 'shippingNumber', placeHolder: 'Número del envío'},
      {fieldName: 'containerNumber', placeHolder: 'Número de contenedor'},
      {fieldName: 'itemCode', placeHolder: 'Código del artículo'}
    ];
  } else if (module === 'inbound') {
    parameters = [
      {fieldName: 'text', placeHolder: 'Número de contenedor'},
    ];
  }

  return parameters;
}