import React, {useEffect, useState} from 'react';
import {Col, Popconfirm, Space} from 'antd';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';

// Store
import {useAppDispatch} from '../../store/store';
import {useSelector} from 'react-redux';
import * as tariffSelectors from '../../store/redux/selectors/tariffSelectors';
import {getAllTariffs, removeTariff} from '../../store/redux/actions/tariffActions';

// Types
import {TariffType} from '../../types/tariffTypes';
import {PaginationType} from '../../types/sharedTypes';

// Components and Subcomponents
import TableComponent from '../subComponents/TableComponent';
import TariffFormModal from './TariffFormModal';
import FiltersComponent from '../subComponents/FiltersComponent';

// Functions and Constants
import {getFilterParameters, getTableColumns} from '../../utils/componentsUtils';


const TariffTable = () => {
  const [paginationData, setPaginationData] = useState<PaginationType>({
    page: 1,
    size: 10,
    text: ''
  });
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [tariffToEdit, setTariffToEdit] = useState<TariffType | null>();
  const dispatch = useAppDispatch();
  const { rows,count, size, page } = useSelector(tariffSelectors.allTariffsSelector);
  const filterParameters = getFilterParameters('tariff')

  useEffect(() => {
    dispatch(getAllTariffs(paginationData));
  }, [paginationData]);

  const handleFormModal = (visible: boolean, tariffId?: string) => {
    if(visible){
      const tariffToEdit = rows.find(tariff => tariff.tariffId === tariffId);
      setTariffToEdit(tariffToEdit);
    } else {
      setTariffToEdit(null);
    }
    setFormModalVisible(visible);
  }

  const actionButtons = (tariffId: string) => {
    return (
      <Space>
        <EditOutlined className={'cursor-pointer hover:text-blue-dkt-400'}
                      onClick={() => handleFormModal(true, tariffId)} />
        <Popconfirm title={'¿Deseas borrar esta tarifa?'} okText={'Confirmar'} cancelText={'Cancelar'}
                    onConfirm={() => dispatch(removeTariff(tariffId))}>
          <DeleteOutlined className={'cursor-pointer hover:text-red-dkt-400'} />
        </Popconfirm>
      </Space>
    )
  };

  return (
    <>
      <Col md={24} xs={24} className={'mt-5'}>
        <FiltersComponent filterParameters={filterParameters} 
                          onChangeFilters={({ text }) => setPaginationData({ page, size, text: text })} />
      </Col>

      <TableComponent pageSize={size} currentPage={page} total={count} filterText={paginationData.text ?? ''}
                      dataSource={rows} scrollX={1200} rowKey={'tariffId'}
                      columns={getTableColumns('tariff', actionButtons)}
                      onChangePage={setPaginationData} />

      {formModalVisible &&
        <TariffFormModal tariffToEdit={tariffToEdit ?? null} onToggleModal={handleFormModal} />
      }
    </>
  );
}

export default TariffTable;