import React, {FunctionComponent, useState} from 'react';
import {Row, Col, Modal, Space} from 'antd';
import PropTypes from 'prop-types';
import {DeleteOutlined} from '@ant-design/icons';

// Store
import {useAppDispatch} from '../../store/store';
import {uploadTariffFile} from '../../store/redux/actions/tariffActions';

// Components and Subcomponents
import ButtonComponent from '../subComponents/ButtonComponent';
import TitleComponent from '../subComponents/TitleComponent';
import InputComponent from '../subComponents/InputComponent';
import UploadComponent from '../subComponents/UploadComponent';
import {ERROR_MODAL} from '../subComponents/responseModals';

// Functions and Constants
import {isEmpty} from '../../utils/functions';


type UploadTariffsModalType = {
  onToggleModal: (visible: boolean) => void;
};
const UploadTariffsModal: FunctionComponent<UploadTariffsModalType> = (
  {
    onToggleModal
  }) => {
  const [file, setFile] = useState({ name: '' });
  const dispatch = useAppDispatch();

  const acceptFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

  const handleSubmit = () => {
    if(file.name === ''){
      ERROR_MODAL('Ningún archivo ha sido agregado.')
      return;
    }

    const formData = new FormData();
    formData.append('file', file as File, file.name);
    dispatch(uploadTariffFile(formData));
    onToggleModal(false);
  };

  return (
    <Modal open={true} closable={false} maskClosable={false} keyboard={false}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cancelar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={'Cargar'}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick={handleSubmit}/>
           ]}>
      <Row>
        <Col md={24} xs={24} className={'mb-6'}>
          <TitleComponent level={4} text={'Cargue de tarifas'} className={'mb-1 leading-6 font-medium text-blue-dkt-400'} />
          <p className="m-0 text-md text-grey-dkt-500">
            Selecciona el archivo que deseas cargar.
          </p>
        </Col>

        <Col md={24} xs={24}>
          <UploadComponent acceptFormat={acceptFormat} onUpload={(file: File) => setFile(file)}
                           supportedFilesText={'Archivos soportados: .xlsx'} />
        </Col>

        {!isEmpty(file.name) &&
          <Col md={24} xs={24} className={'mt-6'}>
            <Space size={'small'} className={'w-full flex [&>div]:contents'}>
              <InputComponent readOnly={true} disabled={true} value={file.name} title={file.name}/>
              <ButtonComponent text={''} icon={<DeleteOutlined/>}
                               className={'bg-orange-dkt-400 hover:bg-orange-dkt-500 focus:outline-none focus:ring-2 ' +
                                 'focus:ring-offset-2 focus:ring-orange-dkt-300 '}
                               onClick={() => setFile({name: ''})}/>
            </Space>
          </Col>
        }
      </Row>
    </Modal>
  );
}

UploadTariffsModal.propTypes = {
  onToggleModal: PropTypes.func.isRequired,
};
UploadTariffsModal.defaultProps = {
  onToggleModal: () => {
    return null;
  }
};

export default UploadTariffsModal;