// Libraries
import React, {FunctionComponent} from 'react';
import {Row, Col, Form, Modal} from 'antd';
import PropTypes from 'prop-types';
import {useAppDispatch} from '../../store/store';

// Store
import {updateThreshold} from '../../store/redux/actions/thresholdActions';

// Types
import {ThresholdType} from '../../types/thresholdTypes';

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import InputComponent from '../subComponents/InputComponent';


type ThresholdFormModalType = {
  thresholdToEdit: ThresholdType | null;
  onToggleModal: (visible: boolean) => void;
};
const ThresholdFormModal: FunctionComponent<ThresholdFormModalType> = (
  {
    thresholdToEdit, onToggleModal
  }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleSubmit = (values: ThresholdType) => {
    values.thresholdId = thresholdToEdit!.thresholdId;
    dispatch(updateThreshold(values));
    onToggleModal(false);
  }

  return (
    <Modal title={'Editar umbral'}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false)} />,
             <ButtonComponent key={'submit'} text={'Editar'}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout="vertical" form={form} initialValues={thresholdToEdit ?? {}}
            onFinish = {handleSubmit}>
        <Row gutter={10}>
          <Col md={12} xs={24}>
            <FormItemComponent name={'reference'} label={'Referencia'} required={true}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'subDeparture'} label={'Subpartida'} required={true}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'type'} label={'Tipo'} required={true}
                               child={<InputComponent disabled={true} />}
            />
          </Col>
          <Col md={12} xs={24}>
            <FormItemComponent name={'threshold'} label={'Umbral'} required={true}
                               child={<InputComponent type={'number'} />}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

ThresholdFormModal.propTypes = {
  thresholdToEdit: PropTypes.any.isRequired,
  onToggleModal: PropTypes.func.isRequired,
};
ThresholdFormModal.defaultProps = {
  thresholdToEdit: null,
  onToggleModal: () => {
    return null;
  }
};

export default ThresholdFormModal;