import {useMediaQuery} from 'react-responsive';


export const isEmpty = (element: string | object | null | undefined) => {
  return element === undefined || element === 'undefined'
    || element === null || element === 'null' || element === ''
    || (Array.isArray(element) && element.length === 0)
    || (Object.keys(element).length === 0);
};

/* -------------------- Session ----------------------- */
export const isSignedIn = () => {
  const accessToken = getLocalStorageItem('accessToken');
  const role = getLocalStorageItem('role');
  const roleName = getLocalStorageItem('roleName');
  const userName = getLocalStorageItem('userName');
  const expiredIn = getLocalStorageItem('expiredIn');
  const incorrectInfo = isEmpty(accessToken) || isEmpty(role) || isEmpty(roleName) || isEmpty(userName) || isEmpty(expiredIn)
    || roleName === 'Desconocido';
  const tokenExpired = new Date() > new Date(expiredIn!);

  if(incorrectInfo || tokenExpired) clearStorage();

  return !incorrectInfo && !tokenExpired;
};

/* --------------------- Storage ----------------------- */
export const setSessionStorageItem = (fieldName: string, value: string) => {
  sessionStorage.setItem(fieldName, value);
};
export const setLocalStorageItem = (fieldName: string, value: string) => {
  localStorage.setItem(fieldName, value);
};
export const getSessionStorageItem = (fieldName: string) => {
  return sessionStorage.getItem(fieldName);
};
export const getLocalStorageItem = (fieldName: string) => {
  return localStorage.getItem(fieldName);
};
export const clearStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

/* --------------------- Window Actions ----------------------- */
export const getWindowInformation = () => {
  const pathname = window.location.pathname;
  const search = window.location.search;

  return { pathname, search };
};
export const reloadWindow = () => {
  window.location.reload();
};
export const redirectWindow = (pathname: string) => {
  window.location.href = pathname;
};
export const screenSizeValidator = (sizeValidator: string) => {
  return useMediaQuery({ query: sizeValidator });
};

/* ----------------------- Currency ------------------------ */
export function currencyFormat(value?: string){
  const text = `${value}`
    .replace('.', ',')
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');

  return `${text}`;
}
export function currencyToNumber(value: string){
  const splitValue = value.split(',');
  let newValue;
  if(splitValue.length === 1){
    newValue = parseFloat(value);
  } else {
    newValue = parseFloat(splitValue[0].replaceAll('.', '') + '.' + splitValue[1]);
  }

  return newValue;
}

/* ------------------------------ Dates --------------------- */
export const getMonths = () => {
  return [
    { id: 1, value: 'Enero' },
    { id: 2, value: 'Febrero' },
    { id: 3, value: 'Marzo' },
    { id: 4, value: 'Abril' },
    { id: 5, value: 'Mayo' },
    { id: 6, value: 'Junio' },
    { id: 7, value: 'Julio' },
    { id: 8, value: 'Agosto' },
    { id: 9, value: 'Septiembre' },
    { id: 10, value: 'Octubre' },
    { id: 11, value: 'Noviembre' },
    { id: 12, value: 'Diciembre' },
  ];
}

/* ----------------------- Base 64 to File ------------------------ */
export function base64ToFile(data: string){
  const name = 'archivo.xlsx';
  const downloadLink = document.createElement('a'); 
  downloadLink.href = 'data:application/octet-stream;base64,' + data;
  downloadLink.download = name;
  downloadLink.click();
  downloadLink.remove();
}

/* ------------------------------ Text --------------------- */
export function textCleanup(value: string){
  // eslint-disable-next-line
  return value.replace(/[^A-Za-z0-9\-\ ]/g, '').trim();
}
