import request from './requestWrapper';

// Types
import {PaginationType} from '../types/sharedTypes';
import {ThresholdType} from '../types/thresholdTypes';

function getAll(data: PaginationType) {
  return request({
    url: 'threshold/getAllPaginated',
    method: 'GET',
    params: data
  });
}
function getTemplate() {
  return request({
    url: 'threshold/getTemplate',
    method: 'GET',
  });
}
function upload(formData: FormData) {
  return request({
    url: 'threshold/upload',
    method: 'POST',
    data: formData
  });
}
function update(threshold: ThresholdType) {
  return request({
    url: 'threshold/update',
    method: 'PUT',
    data: threshold,
  });
}
function remove(thresholdId: string) {
  return request({
    url: `threshold/delete/${thresholdId}`,
    method: 'DELETE',
  });
}

export default {
  getAll, upload, getTemplate, update, remove
}