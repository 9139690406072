/* --------------- General ---------------*/
export const generalTypes = {
  IS_LOADING: 'IS_LOADING',
  SET_CURRENT_ROUTE: 'SET_CURRENT_ROUTE',
};

/* --------------- Session ---------------*/
export const remissionTypes = {
  GET_ALL_REMISSIONS: 'GET_ALL_REMISSIONS',
};

/* --------------- Session ---------------*/
export const sessionTypes = {
  LOGGED_IN: 'LOGGED_IN',
};

/* --------------- CTN ---------------*/
export const ctnTypes = {
  GET_ALL_CTN: 'GET_ALL_CTN',
};
/* --------------- CTN ---------------*/
export const tariffTypes = {
  GET_ALL_TARIFFS: 'GET_ALL_TARIFFS',
};

/* --------------- Thresholds ---------------*/
export const thresholdTypes = {
  GET_ALL_THRESHOLDS: 'GET_ALL_THRESHOLDS',
};

/* --------------- Inbounds ---------------*/
export const inboundTypes = {
  GET_ALL_INBOUNDS: 'GET_ALL_INBOUNDS',
};

/* --------------- Users ---------------*/
export const userTypes = {
  GET_USER_PARAMS: 'GET_USER_PARAMS',
  GET_ALL_USERS: 'GET_ALL_USERS',
};
