import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Col, Layout, Menu} from 'antd';
import {HomeOutlined, TeamOutlined, LogoutOutlined, StockOutlined, ImportOutlined, BarChartOutlined,
  WalletOutlined
} from '@ant-design/icons';

// Store
import * as generalSelectors from '../../store/redux/selectors/generalSelectors';
import {setCurrentRoute} from '../../store/redux/actions/generalActions';
import {useAppDispatch} from '../../store/store';

// Subcomponents
import ButtonComponent from '../subComponents/ButtonComponent';

// Constants and Functions
import {routes} from '../../utils/routes';
import {ROLES} from '../../utils/constants';
import {clearStorage, getLocalStorageItem, getWindowInformation, reloadWindow} from '../../utils/functions';
const {Sider} = Layout;

const Navbar = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentRoute = useSelector(generalSelectors.currentRouteSelector) ?? routes.home;
  const userName = getLocalStorageItem('userName');
  const roleName = getLocalStorageItem('roleName');
  const isAdmin = roleName === ROLES.admin;

  const setNavItems = () => {
    const navItems = [];
    navItems.push({ key: routes.home, icon: <HomeOutlined />, label: isCollapsed ? '' : 'Remisiones' });
    navItems.push({ key: routes.ctn, icon: <WalletOutlined />, label: isCollapsed ? '' : 'CTN' });
    navItems.push({ key: routes.tariff, icon: <BarChartOutlined />, label: isCollapsed ? '' : 'Tarifas' });
    navItems.push({ key: routes.thresholds, icon: <StockOutlined />, label: isCollapsed ? '' : 'Umbrales' });
    navItems.push({ key: routes.inbound, icon: <ImportOutlined />, label: isCollapsed ? '' : 'Inbound' });
    isAdmin && navItems.push({ key: routes.users, icon: <TeamOutlined />, label: isCollapsed ? '' : 'Usuarios' });

    return navItems;
  };

  const navItems = setNavItems();

  useEffect(() => {
    const { pathname } = getWindowInformation();
    dispatch(setCurrentRoute(pathname));
  }, []);

  const handleRouteSelector = (route: string) => {
    dispatch(setCurrentRoute(route));
    navigate(route, { replace: false });
  };
  
  const handleLogout = () => {
    dispatch(setCurrentRoute(routes.home));
    clearStorage();
    reloadWindow();
  };

  return (
    <Sider breakpoint="md"
           className={'h-screen min-h-screen bg-grey-dkt-800 border-r md:flex md:flex-shrink-0 sticky bottom-0 top-0'}
           onBreakpoint={broken => {
             setCollapsed(broken);
           }}
    >
      <Col className={'flex flex-col pt-4 pb-4'}>
        <Col className="logo p-4 min-h-20">
          <img className="w-full mx-auto"
               src={'https://recruiter-v2-245yjcnikq-uc.a.run.app/assets/images/logo_decathlon_colombia.png'} />
        </Col>
        <Menu mode="inline" selectedKeys={[currentRoute]} items={navItems}
              className={'border-0 bg-grey-dkt-800 text-grey-dkt-300 [&>li]:px-4 ' +
                '[&>.ant-menu-item-selected]:bg-grey-dkt-800 [&>.ant-menu-item-selected]:text-white ' +
                '[&>li:not(.ant-menu-item-selected):hover]:bg-grey-dkt-700 [&>li:hover]:text-white ' +
                '[&>li:not(.ant-menu-item-selected):active]:bg-grey-dkt-800 [&>li:after]:border-r-0 ' +
                '[&>.ant-menu-item-selected:after]:border-0 ' +
                `${isCollapsed ? '[&>li>.anticon]:ml-4 ' : '[&>li>.anticon]:ml-0 '}`
              }
              onClick={(item) => handleRouteSelector(item.key)}
        />
      </Col>
      <Col className={'flex border-t border-grey-dkt-600 p-4 bg-red bottom-0 absolute w-full'}>
        <Col className={'flex-shrink-0 w-full'}>
          {!isCollapsed &&
            <Col className={'group'}>
              <p className={'text-sm font-medium text-grey-dkt-300 group-hover:text-grey-dkt-200'}>
                {userName}
              </p>
              <p className={'text-sm text-grey-dkt-400 group-hover:text-grey-dkt-200'}>
                {roleName}
              </p>
            </Col>
          }
          <ButtonComponent text={isCollapsed ? '' : 'Cerrar sesión'}
                           icon={<LogoutOutlined />}
                           className={'w-full h-10 text-white bg-orange-dkt-400 hover:bg-grey-dkt-700 ' +
                             `${isCollapsed ? 'mt-0 ' : 'mt-4 '}`
                           }
                           onClick={handleLogout} />
        </Col>
      </Col>
    </Sider>
  );
}

export default Navbar;