// Libraries
import {Navigate, Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Layout} from 'antd';

// Store
import * as sessionSelectors from '../../store/redux/selectors/sessionSelectors';

// Components
import Login from '../session/Login';
import Users from '../users/Users';
import Remissions from '../remission/Remissions';
import CTN from '../ctn/CTN';
import Tariffs from '../tariffs/Tariffs';
import Thresholds from '../thresholds/Thresholds';
import Inbounds from '../inbounds/Inbounds';

// Constants and Functions
import {routes} from '../../utils/routes';
import {ROLES} from '../../utils/constants';
import {getLocalStorageItem} from '../../utils/functions';
const {Content} = Layout;

function Router() {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedInSelector);
  const roleName = getLocalStorageItem('roleName');
  const isAdmin = roleName === ROLES.admin;

  return (
    <Content className={`flex flex-col w-0 flex-1 overflow-hidden min-h-screen ${isLoggedIn ? 'py-6 px-8' : ''}`}>
      <Routes>
        {isLoggedIn
          ?
          <>
            {<Route path={routes.home} element={<Remissions />} />}
            {<Route path={routes.ctn} element={<CTN />} />}
            {<Route path={routes.tariff} element={<Tariffs />} />}
            {<Route path={routes.thresholds} element={<Thresholds />} />}
            {<Route path={routes.inbound} element={<Inbounds />} />}
            {isAdmin && <Route path={routes.users} element={<Users />} />}

            <Route path={'*'} element={<Navigate to={routes.home} />} />
          </>
          :
          <>
            <Route path={routes.authentication} element={<Login />} />
            <Route path={routes.login} element={<Login />} />

            <Route path={'*'} element={<Navigate to={routes.login} />} />
          </>
        }
      </Routes>
    </Content>
  );
}

export default Router;