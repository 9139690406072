// Functions and Constants
import {isSignedIn} from '../../../utils/functions';

// Types
import {SessionActionType} from '../../../types/sessionTypes';

const initialState = {
  loggedIn: isSignedIn(),
};

const sessionReducer = (state = initialState, action: SessionActionType) => {
  switch (action.type) {
    default:
      return { ...state };
  }
};

export default sessionReducer;
