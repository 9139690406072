import request from './requestWrapper';

// Types
import {PaginationType} from '../types/sharedTypes';
import {TariffType} from '../types/tariffTypes';

function getAll(data: PaginationType) {
  return request({
    url: 'tariff/getAllPaginated',
    method: 'GET',
    params: data
  });
}
function getTemplate() {
  return request({
    url: 'tariff/getTemplate',
    method: 'GET',
  });
}
function upload(formData: FormData) {
  return request({
    url: 'tariff/upload',
    method: 'POST',
    data: formData
  });
}
function update(tariff: TariffType) {
  return request({
    url: 'tariff/update',
    method: 'PUT',
    data: tariff,
  });
}
function remove(tariffId: string) {
  return request({
    url: `tariff/delete/${tariffId}`,
    method: 'DELETE',
  });
}

export default {
  getAll, getTemplate, upload, update, remove
}