import React, { useState } from 'react';
import {Row, Col} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

// Types
import {UserType} from '../../types/userTypes';

// Components and Functions
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import UserList from './UserList';
import UserFormModal from './UserFormModal';


const Users = () => {
  const [userToEdit, setUserToEdit] = useState<UserType | undefined>(undefined)
  const [userFormModalVisible, setUserFormModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleModalVisible = (visible: boolean, isEditing?: boolean, userToEdit?: UserType) => {
    setUserFormModalVisible(visible);
    setIsEditing(isEditing ?? false);
    setUserToEdit(userToEdit ?? undefined);
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3} text={'Usuarios'} className={'font-semibold text-gray-900'} />
      </Col>

      <Col md={10} sm={10} xs={24} className={'text-right'}>
        <ButtonComponent text={'Crear usuario'}
                         icon={<PlusOutlined />}
                         className={'w-fit text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                           'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                         onClick={() => handleModalVisible(true)}/>
      </Col>

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <UserList onToggleModal={handleModalVisible} />
      </Col>

      {userFormModalVisible &&
        <UserFormModal isEditing={isEditing} userToEdit={userToEdit} onToggleModal={handleModalVisible} />
      }
    </Row>
  );
}

export default Users;