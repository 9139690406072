// Constants
import {remissionTypes} from '../types';
import {RemissionActionType} from '../../../types/remissionTypes';

const initialState = {
  remissionResult: {
    count: 0,
    page: 1,
    size: 10,
    rows: []
  },
}

const remissionReducer = (state = initialState, action: RemissionActionType) => {
  switch (action.type) {
    case remissionTypes.GET_ALL_REMISSIONS:
      return {
        ...state,
        remissionResult: action.remissionResult
      };
    default:
      return state;
  }
};

export default remissionReducer;
