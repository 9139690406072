// Constants
import {UserActionType} from '../../../types/userTypes';
import {userTypes} from '../types';

const initialState = {
  users: [],
  params: {
    roleTypes: [],
  }
}

const userReducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
    case userTypes.GET_USER_PARAMS:
      return {
        ...state,
        params: action.params
      };
    case userTypes.GET_ALL_USERS:
      return {
        ...state,
        users: action.users
      };
    default:
      return state;
  }
};

export default userReducer;
