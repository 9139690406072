import React, { useState } from 'react';
import {Row, Col} from 'antd';
import {DownloadOutlined, UploadOutlined} from '@ant-design/icons';

// Store
import {useAppDispatch} from '../../store/store';
import {getTariffTemplate} from '../../store/redux/actions/tariffActions';

// Components
import TitleComponent from '../subComponents/TitleComponent';
import ButtonComponent from '../subComponents/ButtonComponent';
import TariffTable from './TariffTable';
import UploadTariffsModal from './UploadTariffsModal';

// Constants and Functions
import {getLocalStorageItem} from '../../utils/functions';
import {ROLES} from '../../utils/constants';


const Tariffs = () => {
  const dispatch = useAppDispatch();
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const roleName = getLocalStorageItem('roleName');
  const isAdmin = roleName === ROLES.admin;

  const handleModalVisible = (visible: boolean) => {
    setUploadModalVisible(visible);
  };

  return (
    <Row>
      <Col md={14} sm={14} xs={24}>
        <TitleComponent level={3} text={'Tarifas'} className={'font-semibold text-gray-900'} />
      </Col>

      {isAdmin &&
        <Col md={10} sm={10} xs={24} className={'text-right'}>
          <ButtonComponent text={'Cargar archivo'}
                           icon={<UploadOutlined/>}
                           className={'w-fit text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                             'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                           onClick={() => handleModalVisible(true)}/>
          <ButtonComponent text={'Descargar plantilla'}
                           icon={<DownloadOutlined/>}
                           className={'w-fit text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                             'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                           onClick={() => dispatch(getTariffTemplate())}/>
        </Col>
      }

      <Col md={24} xs={24} className={'mt-5 p-6 border border-grey-dkt-300 rounded-lg overflow-hidden bg-white'}>
        <TariffTable />
      </Col>

      {uploadModalVisible && <UploadTariffsModal onToggleModal={setUploadModalVisible} />}
    </Row>
  );
}

export default Tariffs;