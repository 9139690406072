// Libraries
import React, {FunctionComponent} from 'react';
import {Table} from 'antd';
import PropTypes from 'prop-types';

// Types
import { FilterType, PaginationType} from '../../types/sharedTypes';


type TableComponentType = {
  pageSize: number;
  currentPage: number;
  total: number;
  scrollX: number;
  filterText?: string;
  filters?: FilterType;
  rowKey: string;
  className?: string;
  dataSource: any[];
  columns: any[];
  onChangePage: (pagination: PaginationType) => void;
};
const TableComponent: FunctionComponent<TableComponentType> = (
  {
    pageSize, currentPage, total, scrollX, filterText, filters,
    rowKey, className, dataSource, columns, onChangePage
  }) => {
  return (
    <Table dataSource={dataSource} rowKey={rowKey} size={'small'}
           className={className}
           columns={columns}
           scroll={{ x: scrollX, y: 500 }}
           pagination={{
             size: 'small',
             pageSize: pageSize,
             current: currentPage,
             showSizeChanger: true,
             total: total,
             onChange: (page, pageSize) => onChangePage({ page: page, size: pageSize, text: filterText, filters: filters }),
           }} />
  );
}

TableComponent.propTypes = {
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  scrollX: PropTypes.number.isRequired,
  rowKey: PropTypes.string.isRequired,
  filterText: PropTypes.string, 
  filters: PropTypes.object,
  className: PropTypes.string,
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
};
TableComponent.defaultProps = {
  pageSize: 10,
  currentPage: 1,
  total: 1,
  scrollX: 1000,
  rowKey: 'id',
  filterText: '',
  filters: {},
  className: '',
  dataSource: [],
  columns: [],
  onChangePage: () => {
    return;
  },
};

export default TableComponent;